import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faTwitch,
    faPatreon
  } from "@fortawesome/free-brands-svg-icons";
  import '../css/socials.css';

export default function Socials() {
    
    return (
        <div className="social-container">
            <a href="https://www.youtube.com/channel/UCw8MtsqECfhUFnx4KcjawwA"
                target="_blank" rel="noreferrer noopener"
                className="youtube social">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
            <a href="https://twitch.tv/llpqllpq"
                target="_blank" rel="noreferrer noopener"
                className="twitch social">
                <FontAwesomeIcon icon={faTwitch} size="2x" />
            </a>
            <a href="https://www.patreon.com/LLPQ"
                target="_blank" rel="noreferrer noopener"
                className="patreon social">
                <FontAwesomeIcon icon={faPatreon} size="2x" />
            </a>
        </div>
    );
}