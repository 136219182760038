import '../css/showThisWeek.css';
import React, { Component } from 'react';
import EmailForm from '../js/emailForm';
import Socials from '../js/socials';

class ShowThisWeek extends Component {

  componentDidMount() {
      // document.body.style = 'background: #4ff81be0;'; // YES
      document.body.style = 'background: #d91818;'; // NO
  }

  componentWillUnmount() {
    document.body.style = 'background: #FFBBFF;';
  }

  render() {
      return (
      <div className="showThisWeek">
        <span className="extraText">iS THERE A SHOW THIS WEEK??</span>
        <br className="br-extraText" />
        <span>NO</span>
        <EmailForm />
        <Socials />
      </div>
    );
  }
}

export default ShowThisWeek;