import React from 'react';
import '../css/emailForm.css';

class EmailForm extends React.Component {
    submit(){
        document.getElementById("email-div").style.display = "none";
        document.getElementById("submit-div").style.display = "none";
        document.getElementById("thankyou").style.display = "block";
    }

    render() {
        return (
            
            <div id="signup" className="email-form">
            <div id="mlb2-4072433" className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-4072433">
            <div className="ml-form-align-center ">
                <div className="ml-form-embedWrapper embedForm">
                <div className="ml-form-embedBody ml-form-embedBodyDefault row-form">

                    <div className="ml-form-embedContent">
                    
                        <h4>LLPQ MAILING LIST</h4>
                        <p><strong>Sign up for massive news regarding the future of the show!</strong></p>
                    
                    </div>

                    <form className="ml-block-form" action="https://assets.mailerlite.com/jsonp/352322/forms/82841475356821014/subscribe" data-code="" method="post" target="ifrm1">
                    <div id="email-div" className="ml-form-formContent">
                        <div className="ml-form-fieldRow ml-last-item">
                            <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                            <input aria-label="email" aria-required="true" type="email" className="form-control" data-inputmask="" name="fields[email]" placeholder="Email" autoComplete="email" />
                            </div>
                        </div>
                    </div>

                    <input type="hidden" name="ml-submit" value="1" />

                    <div id="submit-div" className="ml-form-embedSubmit">
                        
                        <button type="submit" className="primary" onClick={this.submit}>Subscribe</button>
                        <button disabled="disabled" style={{display: "none"}} type="button" className="loading">
                        <div className="ml-form-embedSubmitLoad"></div>
                        <span className="sr-only">Loading...</span>
                        </button>
                    </div>

                    <input type="hidden" name="anticsrf" value="true" />
                    </form>
                </div>

                <div id="thankyou" className="ml-form-successBody row-success" style={{display: "none"}}>
                    <div className="ml-form-successContent">
                        <h4>Thank you!</h4>
                        <p>You have successfully joined our subscriber list.</p>
                    </div>
                </div>
                </div>
                <iframe title="ifrm1" id="ifrm1" name="ifrm1" style={{display:"none"}}></iframe>
            </div>
            </div>
            </div>
        );
    }
}

export default EmailForm;